<template>
  <div>
    <div class="content-header row">
      <div class="content-header-left col-md-6 col-12 mb-2">
        <div class="row breadcrumbs-top">
          <h2 class="content-header-title float-start mb-0">
            User Master
          </h2>
        </div>
      </div>
      <div class="content-header-right col-md-6 col-12 mb-2">
        <div class="col-12">
          <div class="breadcrumb-wrapper">
            <ol class="breadcrumb float-end">
              <li class="breadcrumb-item">
                <router-link to="/dashboard">Dashboard</router-link>
              </li>
              <li class="breadcrumb-item active">User Master</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <div class="content-body">
      <section id="dashboard-ecommerce">
        <div class="row match-height">
          <div class="col-xl-12 col-md-12 col-12">
            <div class="card card-congratulation-medal">
              <div class="card-body">
                <div class="row mb-1">
                  <div class="d-flex col-12">
                    <div class="float-start col-6">
                      <div class="input-group input-group-merge w-50">
                        <span class="input-group-text" id="basic-addon-search2"
                          ><vue-feather type="search"></vue-feather></span>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Search..."
                          aria-label="Search..."
                          aria-describedby="basic-addon-search2"
                          v-model="search"
                          @keyup="searchData"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <section id="ajax-datatable">
                  <div class="row">
                    <div class="col-12">
                      <div class="card">
                        <div class="card-datatable">
                          <table
                            class="dt-column-search table table-responsive"
                          >
                            <thead>
                              <tr>
                                <template v-for="head in header" :key="head">
                                  <th>{{ head }}</th>
                                </template>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-show="list.length > 0" v-for="(li, index) in list" :key="li.id">
                                <td>{{ index + paginationData.from }}</td>
                                <td>
                                  <img class="avatar " :src="li.profile_url ? li.profile_url : localImage" alt="Profile" height="55" width="55" />
                                </td>
                                <td>{{ li.first_name }}</td>
                                <td>{{ li.middle_name }}</td>
                                <td>{{ li.last_name }}</td>
                                <td>{{ li.email }}</td>
                                <td>{{ li.mobile }}</td>
                                <td>{{ li.user_type }}</td>
                                <td>
                                  <div class="d-flex flex-column">
                                      <label class="form-check-label mb-50" :for="`customSwitch`+li.id">Block</label>
                                      <div class="form-check form-switch form-check-danger">
                                          <input type="checkbox" 
                                          class="form-check-input" 
                                          :id="`customSwitch`+li.id" 
                                          :checked="(li.is_block == 1) ? true : false" 
                                          @change="checkBlock(li.id)" 
                                          v-model="is_block" />
                                          <label class="form-check-label" :for="`customSwitch`+li.id">
                                              <span class="switch-icon-left"><vue-feather type="check"></vue-feather></span>
                                              <span class="switch-icon-right"><vue-feather type="x"></vue-feather></span>
                                          </label>
                                      </div>
                                  </div>
                                </td>
                                <td><span class="badge " :class="(li.is_super_admin) ? `badge-light-success` : `badge-light-primary`">{{ (li.is_super_admin) ? 'Yes' : 'No' }}</span></td>
                                
                              </tr>
                              <tr v-show="list.length === 0">
                                <td colspan="11" class="text-center">No Data Found</td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                <template v-for="head in header" :key="head">
                                  <th>{{ head }}</th>
                                </template>
                              </tr>
                            </tfoot>
                          </table>
                          <div class="row d-flex">
                            <div class="col-12 d-flex">
                              <div class="col-6 showing_entry">
                                Showing {{ paginationData.from }} to
                                {{ paginationData.to }} of
                                {{ paginationData.total }} entries
                              </div>
                              <div class="col-6 pagination-scroll">
                                <Pagination
                                  :data="paginationData"
                                  @pagination-change-page="getResults"
                                  show-disabled="true"
                                  limit="3"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import LaravelVuePagination from "laravel-vue-pagination";
import Form from 'vform';
import localImage from '../../assets/images/user-placeholder.png';

export default {
  name: "User",
  components: {
    Pagination: LaravelVuePagination,
  },
  data() {
    return {
      form: new Form({
        id: null,
        is_block: false,
      }),
      localImage: localImage,
      response: "",
      header: [],
      list: [],
      modalName: "Add",
      paginationData: {},
      search: null,
      token: localStorage.getItem('auth_token')
    };
  },
  async mounted() {
    this.header = [
      "Sr. no.",
      "Profile",
      "First Name",
      "Middle Name",
      "Last Name",
      "Email",
      "Mobile",
      "User Type",
      "is Block",
      "Super Admin",
    ];
    this.getResults();
  },
  methods: {
    async getResults(page = 1) {
      await axios
        .get(process.env.VUE_APP_API_URL+"/user_master?page=" + page, 
        {
          headers: {
            "Content-Type": "application/json",
            'Accept': "application/json",
            "Authorization": `Bearer ${this.token}`
          }
        })
        .then((response) => {
          console.log(response)
          this.paginationData = response.data.data;
        });
      this.list = this.paginationData.data;
    },
    async checkBlock(id) {
      await axios
      .post(process.env.VUE_APP_API_URL+"/user_master/update-block/" + id,
        {
          is_block: this.is_block,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": `Bearer ${this.token}`
          },
        }
      )
      .then((response) => {
        if (response.data.status) {
          this.$toast.success(response.data.message);
          location.reload();
        } else {
          this.$toast.error(response.data.error?.[0]);
        }
      })
      .catch((error) => {
        this.$toast.error(error);
      });
    },
    async searchData() {
      await axios
        .post(process.env.VUE_APP_API_URL+"/user_master/search",
          {
            search: this.search,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "Accept": "application/json",
              "Authorization": `Bearer ${this.token}`
            },
          }
        )
        .then((response) => {
          if (response.data.status) {
            this.list = response.data.data.data;
          } else {
            this.$toast.error(response.data.error?.[0]);
          }
        })
        .catch((error) => {
          this.$toast.error(error);
        });
    },
  },
};
</script>
<style>
.form-switch .form-check-label .switch-text-left, .form-switch .form-check-label .switch-icon-left,
.form-switch .form-check-label .switch-text-right, .form-switch .form-check-label .switch-icon-right {
  top: 4px !important;
}

.pagination-scroll {
  overflow: scroll;
    scrollbar-width: none;
}

.pagination-scroll .pagination {
  float: unset !important;
}
</style>